var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Add Page" } }),
      _c("gov-back-link", { attrs: { to: { name: "pages-index" } } }, [
        _vm._v("Back to pages")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c("page-form", {
            attrs: {
              errors: _vm.form.$errors,
              page_type: _vm.form.page_type,
              parent_id: _vm.form.parent_id,
              title: _vm.form.title,
              slug: _vm.form.slug,
              excerpt: _vm.form.excerpt,
              content: _vm.form.content,
              image_file_id: _vm.form.image_file_id,
              collections: _vm.form.collections,
              enabled: _vm.form.enabled
            },
            on: {
              "update:page_type": function($event) {
                return _vm.$set(_vm.form, "page_type", $event)
              },
              "update:parent_id": function($event) {
                return _vm.$set(_vm.form, "parent_id", $event)
              },
              "update:title": _vm.onUpdateTitle,
              "update:slug": function($event) {
                return _vm.$set(_vm.form, "slug", $event)
              },
              "update:excerpt": function($event) {
                return _vm.$set(_vm.form, "excerpt", $event)
              },
              "update:content": function($event) {
                return _vm.$set(_vm.form, "content", $event)
              },
              "update:image_file_id": function($event) {
                return _vm.$set(_vm.form, "image_file_id", $event)
              },
              "update:collections": function($event) {
                return _vm.$set(_vm.form, "collections", $event)
              },
              "update:enabled": function($event) {
                return _vm.$set(_vm.form, "enabled", $event)
              },
              clear: function($event) {
                return _vm.form.$errors.clear($event)
              },
              "image-changed": function($event) {
                _vm.imageChanged = $event
              },
              "alt-text-changed": function($event) {
                _vm.altTextChanged = true
              }
            }
          })
        ],
        1
      ),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _vm.form.$submitting
        ? _c("gov-button", { attrs: { disabled: "", type: "submit" } }, [
            _vm._v("Creating...")
          ])
        : _c(
            "gov-button",
            { attrs: { type: "submit" }, on: { click: _vm.onSubmit } },
            [_vm._v("Create")]
          ),
      _vm.form.$errors.any() ? _c("ck-submit-error") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }